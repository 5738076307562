.ToolCheck{
    background-color: #0D1321;
    padding-top: 7rem;

}
.Toolauflistung{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
    margin: 0 2rem;
}
.Toolauflistung svg{
    margin: 0 -2rem; /*Verbunden imt margin aus Toolauflistung*/
}
.TopText{
    display: flex;
    justify-content: center;
}
.TopText-Wrapper{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 1rem;
}
.TopText-Wrapper h1{
    color:white;
    font-size: 3.5rem;
}
.TopText-Wrapper h3{
    color:white;
    font-weight: 200;
    margin-top: -10px;
    font-size: 2rem;
}

.TopText h1{
 color:white;
}
.Tool-ItemCard img{
    width: 250px;
    border-radius: 0.4rem;
    transform: scale(1);
    transition: ease-in-out 0.1s;
}
.Tool-ItemCard img:hover{
    transform: scale(1.1);
}
.Tool-ItemCard{
    padding: 0.5rem 0.8rem;
    border: 2px solid #18A999;
    border-radius: 0.5rem;
    display: flex;
}
.bundleAuflistung{
    text-align: center;
    padding-bottom: 10rem;
  }
.bundleAuflistung h3{
    color: white;
    font-size: 2rem;
}
#bundleAuflistung-Heading{
    font-size: 50px;
}
.bundleWrapper{
    display: flex;
    justify-content: center; 
    flex-direction: row;
}
.ToolCheck-ShowAllBundles{
    color: white;
    padding-top: 8rem;
}
.ToolCheck-ShowAllBundles a{
    font-size: 1.2rem;
    border: 1px solid white;
    padding: 10px 15px;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    color: white;
}
.ToolCheck-ShowAllBundles a:hover{
    background-color: white;
    color: #0D1321;
}
#Toolauflistung-head{
    color: white;
    margin: 2rem 5vw;
    font-size: 2rem;
}

@media (max-width: 1100px){
 .bundleWrapper{
    flex-wrap: wrap;
    align-items: center;
 }
  
}

@media (max-width: 850px){
    .TopText-Wrapper h1{
        font-size: 7vw;
    }
    .TopText-Wrapper h3{
        font-weight: 200;
        font-size: 5vw;
    }
    #Toolauflistung-head{
        text-align: center;
    }
    .Tool-ItemCard img{
        width: 150px;
    }
   
}
@media (max-width: 600px){
    .Tool-ItemCard img{
        width: 125px;
    }
}


@media (min-width: 1400px){
    .Toolauflistung{
        justify-content: center ;
    }
}