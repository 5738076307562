.Home{
    overflow-x: hidden;
}
.Home-Intro{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0;
}
.Home-Intro h1{
    color:white;
    font-size: 3.5rem;
}
.Home-Intro h3{
    color:white;
    font-weight: 200;
    margin-top: -10px;
    font-size: 2rem;
}
.Home-Body{
    height: auto;
    display: none;
}
.Home-CarouselWrapper{
    width: 80%;
    height: max-content;
    margin: auto;
    padding: 1rem 0;
    overflow: hidden;
}
.Home-Socialproof{
    font-size: 19px;
    color: white;
    text-decoration: underline;
    cursor: pointer;
}
.Home-Wave2{
    transform: rotate(180deg);
}
.Home-WaveDivider div svg{
    fill: #0d1321;
    width: calc(120% +  1.3px);
    height: 50px;
}
.Home-WaveDivider{
    background-color: #5407cb;
}
.Home-MidSection{
    padding: 10rem 0;
    margin-top: 5rem;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    /* background-image: url("../../../public/img/Dekor/fullBlobfade.svg"); */
    background-size: cover;
    background-repeat: no-repeat;
}

.Home-Sec1{
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.Home-Sec2{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Home-MidSection div div h3{
    font-size: 2rem;
}
.Home-Sec1{
    margin-bottom: 20rem;
}
.Home-LinkBtn{
    margin-top: 5rem;
}
.Home-LinkBtn a{
    border: 1px solid white;
    padding: 5px 10px;
    border-radius: 0.5rem;
    margin: 0 15px;
    font-size: 1.5rem;
    cursor: pointer;
}
.Home-Sec2{
    margin-top: 20rem;
}
.Home-Top-Lurker{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Home-Top-Lurker h3{
    font-size: 2.5rem;
    letter-spacing: 2px;
    color: white;
}
#Home-Chevron{
    font-size: 2rem;
    animation: bounce 0.5s infinite alternate;
    margin-top: -10px;
}
.Home-Timeline-Content{
    margin-bottom: -20px;
    display: flex;
    padding: 20px 0;
    text-align: center;
}
.Home-Timeline-Content a{
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 100%;
    padding: 8px 0;
    border-radius: 0.5rem;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.3s ease-in-out;
}
.Home-Timeline-Content a:hover{
    letter-spacing: 3px;
}
.Home-Timeline-Content p{
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
    width: 100%;
    padding: 8px 0;
    border-radius: 0.5rem;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    font-size: 18px;
    letter-spacing: 1px;
    transition: 0.3s ease-in-out;
}
.Home-Timeline-Content p:hover{
    letter-spacing: 3px;
}
.Home-Timeline-GrayedOut p{
    background-color: #aaa;
    color: black;
    cursor: not-allowed;
}
.Home-Timeline-GrayedOut p:hover{
    letter-spacing: 1px;
    border: 2px solid transparent;
}
.Home-border1 a{
    border-left: 2px solid hsl(250, 10%, 95%);
    border-right: 2px solid hsl(250, 10%, 95%);
}
.Home-border1 a:hover{
    border: 2px solid hsl(250, 10%, 95%);
}
.Home-border2 a{
    border-left: 2px solid hsl(318, 25%, 85%);
    border-right: 2px solid hsl(318, 25%, 85%);
}
.Home-border2 a:hover{
    border: 2px solid hsl(318, 25%, 85%);
}

.Home-border4 p{
    border-left: 2px solid hsl(22, 90%, 22%);
    border-right: 2px solid hsl(22, 90%, 22%);
}
.Home-border4 p:hover{
    border: 2px solid hsl(22, 90%, 22%);
}
.Home-Questions{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.Home-Questions h3{
    font-size: 2rem;
}
.Home-Questions div{
    color: white;
}
.Home-Questions div i{
    font-size: 40px;
    margin: 2rem;
    cursor: pointer;
}
.Home-ImageGrid{
    margin: 7rem 0;
}
.Home-ATL{
    display: flex;
    gap: 40px;
    font-size: 18px;
}
#Home-ATL-Left{
    border: 1px solid white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
}
#Home-ATL-Right{
    border: 1px solid white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    opacity: 0;
}
.Home-ATL i{
    transform: rotate(-90deg); 
    font-size: 33px;
    height: min-content;
    margin-top: -2px;
    margin-left: -28px;
}
.Home-ATL-Text{
    margin-top: -35px;
}


@keyframes bounce{
    from{
        transform: translateY(0);
    }
    to{
        transform: translateY(-10px);
    }
}

@media (max-width: 1170px){
    #Home-ATL-Left{
        display: none;
    }
    #Home-ATL-Right{
        margin-top: 30px;
        opacity: 1;
    }
    .Home-ATL{
        width: 100%;
        margin-left: 6.2vw;
    }
    .Home-ATL i{
        transform: scaleX(-1) rotate(-90deg);
    }
    .fa-thumbtack{
        display: none;
    }
}
@media (max-width: 1000px){
    .Home-Intro h1{
        font-size: 7vw;
        text-align: center;
    }
    .Home-Intro h3{
        font-weight: 200;
        font-size: 3.5vw;
    }
    .Home-CarouselWrapper{
        width: 100%; 
        }
        .Home-Socialproof{
            padding-left: 3rem;
        }
}
@media (max-width: 600px){

    .Home-Intro h1{
        font-size: 8vw;
    }
    .Home-Intro h3{
        font-size: 4vw;
    }

    .Home-ATL{
        margin-Left:7.9vw
    }
    .Home-Socialproof{
        padding: 0;
        text-align: center;
    }

}