.App {
  text-align: center;
  overflow-x: hidden;
}
*{
  font-family: "Playfair Display", serif;
}
html{
  background-color: #0d1321;
  /* scroll-snap-type: y proximity; */
}
.spacer1{
  height: 1rem;
  width: 100%;
}
.hidden{
  opacity: 0;
  /* transition: all 1.2s; */
  filter: blur(5px);
  transform: translateY(20%);
  transition: filter 1s, transform 1s;
}
.show{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}
.YDropShadow{
  box-shadow: 0px 4px 4px 0 rgba(0,0,0,0.25)
}
@media(prefers-reduced-motion){
  .hidden{
    transition: none;
  }
}
