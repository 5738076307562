.ImageGrid-main {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    transform: rotate(-5deg);
  }

  .ImageGrid-Item {
    height: 200px;
    border-radius: 1rem;
    transition: transform 0.2s; /* Optional: Für einen Hover-Effekt */
  }
  .ImageGrid-Container{
    text-align: center;
  }
  .ImageGrid-Container p{
    position: absolute;
    bottom: 0;
    background-color: black;
    width: 100%;
    border-radius: 0.4rem;
    opacity: 0;
    transition: 0.1s ease-in-out;
    cursor: default;
}
.ImageGrid-Container:hover p{
    opacity: 1;
}
  
  /* Versetze jedes zweite Bild ein wenig nach unten */
  .ImageGrid-Container:nth-child(even) {
    transform: translateY(50px);
  } 
  .ImageGrid-Container:nth-child(odd){
    transform: translateY(0);
  }

  @media (max-width: 850px){
    .ImageGrid-main{
        grid-template-columns: repeat(4, 1fr); 
    }
  }
  @media (max-width: 650px){
    .ImageGrid-Item{
        height: 150px;
    }
  }