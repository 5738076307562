.Guide-section4-Carousel{
    display: flex;
    width: 80%;
    justify-content: center;
}
.Guide-section4-Carousel-Bigimg{
    width: max-content;
    margin-right: 2%;
}
.Guide-section4-Carousel-Bigimg img{
    border: 2px solid white;
    border-radius: 1rem;
    width: 700px;
    height: auto;
    
}
.Guide-section4-Carousel-Text{
    max-width: 700px;
    height: max-content;
    margin-left: 2%;
    border: 2px solid white;
    border-radius: 1rem;
}
.Guide-section4-Carousel-Text-Wrapper{
    margin: 2rem;
}
.Guide-section4-Carousel-Text-Wrapper p{
    color: white;
    font-size: 1.1rem;
    font-weight: 400;
    letter-spacing: 0.8px;
}
.Guide-section4-Carousel-Arrows{
    display: flex;
    align-items: center;
}
#Guide-Arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 2rem;
    margin: 1rem 4rem;
}
.Guide-Carousel-Wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Guide-sec4-Carousel-LArrow{
    border: 1px solid white;
    cursor: pointer;
    color: white;
}

.Guide-sec4-Carousel-RArrow{
    border: 1px solid white;
    cursor: pointer;
    color: white;
}

.Guide-BTN-Disabled{
    opacity: 0.4;
    cursor: default;
}
.Guide-MidProgress p{
    color: white;
}


@keyframes fade-in {
    from { opacity: 1; }
    to { opacity: 1; }
  }
@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 1; }
}
  .Guide-section4-Carousel.fade-out {
    animation: fade-out 0.4s forwards;
  }
  
  .Guide-section4-Carousel.fade-in {
    animation: fade-in 0.4s forwards;
  }
  @media (max-width: 1200px){
    .Guide-section4-Carousel{
        flex-direction: column;
        width: 100%;
    }
    .Guide-section4-Carousel-Text{
        width: 100%;
        margin: 0;
        padding: 0;
    }
  }
  @media (max-width: 750px){

    .Guide-Carousel-Wrapper{
    }
    .Guide-section4-Carousel-Bigimg img{
        width: 95vw;
    }
    .Guide-section4-Carousel-Text{
        width: 95vw;
    }
    .Guide-section4-Carousel-Text-Wrapper{
        margin: 0 5px;
    }
  
}