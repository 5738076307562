:target{
    scroll-margin-top: var(--navbar-height);
}
.Guide{
    scroll-behavior: smooth;
    scroll-snap-align: start;
    scroll-margin-top: 40px;
    background-color: #0d1321;
    padding-top: 7rem;
    position: relative;
    overflow: visible;
}

.Guide-Intro{
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;
}
.Guide-Intro h1{
    color:white;
    font-size: 3.5rem;
}
.Guide-Intro h2{
    color:white;
    font-size: 3vw;
}
.Guide-Intro h3{
    color:white;
    font-weight: 200;
    margin-top: -10px;
    font-size: 2rem;
}
.Guide-Intro p{
    color: white;
    font-size: 2rem;
    width: 70%;
}
.Guide-Guide{
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Guide-Top-Navigation{
    padding-bottom: 3rem;
}
.Guide-Top-Navigation a{
    padding: 5px;
    margin: 5px;
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.Guide-Top-Navigation ul{
    list-style-type: none;
    display: flex;
    padding: 0;
    margin: 0;
}
.Guide-Top-Navigation ul li{
    padding: 5px;
    margin: 0.5rem;
}
/* HEX GRADIENTS 
#de2bcf
#d525cf
#cc1ecf
#c218cf
#b812ce
#ad0cce
#a307ce <-
#9804ce
#8c02cd
#7f02cd
#7202cc
#6404cc
#5407cb
#410aca
*/
.Guide-Top-Navigation ul li:nth-child(2){
    background-color: #410aca;
}
.Guide-Top-Navigation ul li:nth-child(3){
    background-color: #5407cb;
}
.Guide-Top-Navigation ul li:nth-child(4){
    background-color: #6404cc;
}
.Guide-Top-Navigation ul li:nth-child(5){
    background-color: #7202cc;
}
.Guide-Top-Navigation ul li:nth-child(6){
    background-color: #8c02cd;
}
.Guide-Top-Navigation ul li:nth-child(7){
    background-color: #9804ce;
}

.Guide-Content{
    border-radius: 0.5rem;
    /* background-color: #0d1321; */
    display: flex;
    flex-direction: column;
    align-items: center;
    height:auto;
    width: 100%;
}
.Guide-Section-Content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0 10rem 0;
}
.Guide-Section-Headings{
    color: white;
    font-size: 2rem;
}

#section1{
    background: linear-gradient(0deg,#410aca, #d525cf);
    /* background: linear-gradient(0deg,#410aca, #270c6d); */
    /* animation: colorChanger 12s ease-in-out infinite; */
    width: 100%;
}
  .Guide-Sec1-Content{
    padding: 4rem 7rem 4rem 7rem;
    border-radius: 1rem;
    border: 1px solid white;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.Guide-Sec1-Content-Text{
    margin-right: 4rem;
}
.Guide-Sec1-Content-Text-Parts ul{
    list-style: none;
}
.Guide-Sec1-Content-Text-Parts ul li{
    color:white;
    margin: 10px 0 10px 0;
    padding: 3px;
    border: 1px solid white;
    cursor: pointer;
}
.Guide-Sec1-Content-Text-Parts-List-clicked{
    background-color: white;
}
.Guide-Sec1-Content-Text-Parts-List-clicked p{
    /* color:#410ACA; */
    color: #000000;
}

.Guide-Sec1-Content-Text-Parts ul li p{
    padding: 0;
    margin: 0;
    margin-left: 10px;
}
.Guide-Sec1-Content-img{
    margin-left: 4rem;
}
.Guide-Sec1-Content-img img{
    border-radius: 1rem;
}
.Guide-Sec1-Content-img-Main{
    box-shadow: 15px 12px 0 #000000;
    height: 350px;
}

.Guide-Sec1-Content-img-Parts{
    height: 200px;
    position: absolute;
    box-shadow: 5px 10px 0px white;
    opacity: 0;
    transition: ease-in 0.5s;
}
.Guide-Sec1-Content-img-Parts-animation{
    transform: translate(50%, -50%);
    opacity: 1;
}

#section3{
    width: 100%;
    margin-bottom: -8px;
}
.section3-Content{
    padding-top: 8rem;
    width: 80%;
    margin: auto;
    margin-bottom: 8rem;
}
.Guide-section3-SubHeading{
    color:white;
    margin-top: -15px;
    font-weight: 500;
    font-size: 1.4rem;
}
.Guide-Movementimages{
    display: grid;
    padding: 5rem 0 5rem 0;
    grid-auto-flow: column;
    gap: 3rem;
    justify-content: center;
}
.Guide-MovementText{
    opacity: 0;
    transition: opacity 0.2s ease-out, blur 0.2s ease-out;
    filter: blur(8px);

}
.Guide-MovementTextVisible{
    opacity: 1;
    filter: blur(0);
    
}
.Guide-Movement-TextWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.Guide-Movement-TextWrapper p{
    color:white;
}
.Guide-Movement-TextWrapper-Top{
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin-bottom: 20px;
    border: 1px solid white;
    border-radius: 0.5rem;
}

.Guide-Movement-TextWrapper-Bottom{
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    border: 1px solid white;
    border-radius: 0.5rem;
}
.Guide-Movementimage-Square{
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    background-color: white;
    border-radius: 3px;
    opacity: 0.3;
}
.Guide-Movementimage-Square.Guide-Movement-Selec{
    opacity: 1;
}

.Guide-Movementimages-div:nth-child(2){
    transition-delay: 200ms;
}
.Guide-Movementimages-div:nth-child(3){
    transition-delay: 400ms;
}
.Guide-Movementimages-div:nth-child(4){
    transition-delay: 600ms;
}
.Guide-Movementimages-div:nth-child(5){
    transition-delay: 800ms; 
}

/* WAVE */
.Guide-Wave {
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}
.Guide-Wave svg {
    position: relative;
    display: block;
}
.Guide-W1 svg{
    width: calc(109% + 1.3px);
    height: 120px;
    transform: rotateY(180deg);
}
.Guide-W1 .shape-fill {
    fill: #0D1321;
}
.Guide-W2 svg{
    width: calc(100% + 1.3px);
    height: 110px;
}
.Guide-W2 .shape-fill {
fill: #410ACA;
}

#section4{
    /* background-color: #9804CE; */
    background: linear-gradient(0deg, rgb(60, 10, 125),#9804CE);
    width: 100%;
    padding: 10rem 0;
}
.Guide-section4-Content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Guide-section4-TopNav{
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: space-between;
    align-items: center;
}
.Guide-section4-TopNav-ToolRef{
    border: 2px solid white;
    height: max-content;
    display: flex;
    border-radius: 0.3rem;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.Guide-section4-TopNav-ToolRef p{
    color: white;
    margin: 0 15px 0 15px;
}
.Guide-section4-TopNav-ToolRef div{
    background-color: white;
    padding: 0.5rem;
    border-radius: 0.2rem;
    transition: all 0.2s ease-in-out;
}
.Guide-section4-TopNav-ToolRef:hover{
    background-color: white;
}
.Guide-section4-TopNav-ToolRef:hover a{
    color:black;
}
.Guide-section4-TopNav-ToolRef:hover div{
    background-color: #9804CE;
}
.Guide-section4-TopNav-ToolRef:hover div i{
    color: white;
}
.Guide-section4-MainContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
}

.Guide-section5{
    width: 100%;
    margin-bottom: 5rem;
}
.Guide-sec5-Wave svg{
    width: 100%;
    transform: rotate(180deg);
    margin-top: -10px;
}
.Guide-FAQ{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.Guide-FAQ h3{
    font-size: 2rem;
}
.Guide-section6{
    color: white;
    width: 100%;
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.Guide-section6 h3{
    font-size: 2rem;
}
.Guide-section6 div i{
    font-size: 40px;
    margin: 2rem;
    cursor: pointer;
}
@media (max-width: 1200px){
    .Guide-Top-Navigation a{
        margin: 0;
        padding: 0;
    }
}

@media (max-width: 1000px){
    .Guide-Intro h1{
        font-size: 7vw;
        text-align: center;
    }
    .Guide-Intro h3{
        font-weight: 200;
        font-size: 3.5vw;
    }
}
@media (max-width: 950px){

    .Guide-Sec1-Content{
        flex-direction: column;
        padding: 0rem 2rem 1rem 2rem;
        align-items: center;
        justify-content: center;
    }
    .Guide-Sec1-Content-img{
        padding: 0;
        margin: 1rem 0;
    }
    .Guide-Sec1-Content-Text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
    }
    .Guide-Sec1-Content-Text-Parts ul{
        margin: 0;
        padding: 0;
    }
    .Guide-Sec1-Content-Text-Parts ul li{
        width: 200px;
    }

    .Guide-Top-Navigation ul{
        flex-direction: column;
        text-align: center;
        padding: 0;
        margin: 0;
    }
    .Guide-Top-Navigation ul li{
        border-radius: 5px;
        /* font-size: 12px; */
        /* margin: 3px 0; */
    }
    #text-center{
        text-align: center;
    }
    
}
@media (max-width: 750px){
    .Guide-Movement-TextWrapper div{
        width: 100%;
        text-align: center;
    }
    .Guide-MovementText{
        padding: 0 5px;
    }
    .section3-Content{
        width: 100%;
    }
    .Guide-section3-SubHeading{
        font-size: 1.1rem;
    }
    .Guide-section4-TopNav-Heading{
        padding: 0;
        margin: 0;
    }
    .Guide-section4-TopNav{
        width: 95%;
    }
}