.Bundle-Main {
    margin-top: 5rem;
    color: white;
    text-align: center;
}
#Bundle-Heading{
    font-size: 2.5rem;
}
.Bundle-Display{
    width: 90%;
    margin: auto;
}

.Bundle-LinkWrapper{
    display: flex;
    justify-content: space-between;
    margin: 0 5rem;
}
.Bundle-ShowAll{
    cursor: pointer;
}
.Bundle-ShowAll:hover{
    text-decoration: underline 1px white;
}
.Bundle-RefNote{
    padding-top: 5rem;
}

@media (max-width: 700px){
    .Bundle-Display{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Bundle-LinkWrapper{
        margin: 0 20px;
    }
}