.Tool-Body{
    background-color: #0d1321;
    padding: 5rem 2rem 0 2rem;
}
.Tool-Body-CenterWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 6rem 0;
}
.Tool-Body-Wrapper{
    display: flex;
    justify-content: center;
    border: 1px solid white;
    border-radius: 1rem;
    padding: 1rem;
    width: max-content;
}
.Tool-Body-Wrapper div{
    margin: 0 2rem;
}
.Tool-Image{
    padding: 1rem;
    display: flex;
    align-items: center;
}
.Tool-Image img{
    width: 400px;
    height: auto;
    border-radius: 1rem;
}
.Tool-Divider{
    width: 1px;
    height: 200px;
    background-color: white;
    transform: translateY(25%);
}
.Tool-NoHeading{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    padding-top: 1rem;
}
.Tool-NoHeading div{
    padding: 0;
    margin: 0;
}
.Tool-RightText{
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
}
.Tool-RightText  div h2{
    font-size: 28px;
    margin-bottom: 0;
}
.Tool-BuyButtons{
    display: flex;
    flex-direction: column;
}
.Tool-BuyButtons div div{
    cursor: pointer;
    border: 1px solid white;
    padding: 5px 15px;
    border-radius: 1rem;
    transition: 0.2s ease-in-out;
}
.Tool-BuyButtons div div:hover{
    background-color: white;
    color: #0d1321;
}
.Tool-BuyButtons div div{
    margin: 1rem;
}
.Tool-BuyButtons div{
    display: flex;
    flex-direction: row;
}
.Tool-BuyButtons div p{
    margin: 0;
}
.Tool-BottomText p{
    color: white;
    padding-top: 1rem;
}
.Tool-BottomText{
    max-width: 400px;
}
.Tool-Backarrow{
    color: white;
    margin-bottom: 0.5rem;
    width: max-content;
}
.Tool-Bundleoption p{
    text-decoration: underline;
    text-underline-offset: 4px;
    margin: 0;
    cursor: pointer;
    width: max-content;
    transition: ease-in 0.2s;
}
.Tool-Bundleoption p:hover{
    text-underline-offset: 8px;
}

.Tool-Backarrow:hover{
    cursor: pointer;
    text-decoration: underline;
}
.Tool-NotExists{
    text-align: center;
    margin: 32vh 0;
}
.Tool-NotExists p{
    color: white;
    font-size: 1.2rem;
}

.Tool-RefNote{
    padding-top: 5rem;
}


@media (max-width: 1150px){
    .Tool-Image img{
        width: 300px;
    }
    .Tool-RightText  div h2{
        font-size: 25px;
        margin: 0;
    }
    .Tool-Body-Wrapper div{
        margin: 0 0.5rem;
    }
    .Tool-Divider{
        display: none;
    }
    .Tool-Image{
        padding: 0;
    }
    
}
@media (max-width: 850px){
    .Tool-Body{
        overflow-y: hidden;
    }
    .Tool-Body-Wrapper{
        flex-direction: column;
        align-items: center;
    }
    .Tool-Image img{
        width: 100%;
    }
    .Tool-RightText{
        padding-top: 1rem;
    }
    .Tool-NoHeading{
        padding-top: 2rem;
    }
    .Tool-BuyButtons{
        padding-top: 1rem;
    }
    .Tool-Bundleoption p{
        padding-top: 1rem;
    }
    .Tool-BottomText p{
        padding: 2rem 0;
    }
    
}