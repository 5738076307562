.footer {
    background-color: #131215;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 20px;
  }
  
  .footer-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    position: relative;
    z-index: 5;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #ddd;
    text-decoration: underline white;
  }
  .social-links{
    margin-top: -1.5rem;
    position: relative;
    z-index: 1;
  }
  .social-links a {
    display: inline-block;
    margin: 0 10px;
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .social-links a:hover {
    color: #ddd;
    text-decoration: underline white;
  }
  
  .footer-bottom {
    padding-top: 1 rem;
  }
  