.Ausklapp-Wrap button{
    width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: none;
    color: white;
    text-align: start;
    /* padding: 20px 0; */
    font-size: 1.2rem;
    cursor: pointer;
}
.Ausklapp-keinrotate{
    transform: rotate(0);
    transition: transform 0.5s ease;
    font-size: 15px;
}
.Ausklapp-rotate{
   transform: rotate(180deg); 
   transition: transform 0.5s ease;
   font-size: 15px;
}
.Ausklapp-Answer{
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    border-bottom: 1px solid white;
}
.Ausklapp-Answer p{
    max-width: 700px;
}
@media (max-width: 950px){
    .Ausklapp-Wrap button{
        width: 80vw
    }
    .Ausklapp-Answer p{
        max-width: 80vw;
    }
}