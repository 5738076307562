.CookieConsent-Main{
    background-color: #090d16;
            position: fixed;
            padding: 2rem 5rem 3rem 5rem;
            bottom: 50%;
            right: 50%;
            transform: translate(50%, 50%);
            color: white;
            width: 50vw;
            border-radius: 0.5rem;
}
.CookieConsent-Buttons{
    margin-top: 3rem;
    float: right;
}
.CookieConsent-Buttons button{
    width: 150px;
    height: 40px;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 0;
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight: 600;
}
@media(max-width: 1000px){
   .CookieConsent-Buttons{
    float: none;
    }
    .CookieConsent-Buttons button{
    width: 100px;
    height: 30px;
    }
}
@media(max-width: 700px){
    .CookieConsent-Main{
        padding: 2rem 3rem 3rem 3rem;
        width: 80%;
    }
    .CookieConsent-Main p{
        font-size: 13px;
    }
    .CookieConsent-Buttons button{
        margin-right: 8px;
    }
}