.Imprint-Main{
    color: white;
    margin: auto;
    width: 70%;
    list-style: none;
}
#Privacy-Policy{
    color: white;
    width: 70%;
    margin: auto;
}