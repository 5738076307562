/* CarouselStyles.css */
.carousel-container {
    margin: 0 auto;
    padding: 1rem 0;
  }
 .imageWrapper{
    position: relative;
    display: inline-block;
    margin-bottom: 0.5rem  ;
 }

  .carousel-image {
    display: block;
    width: 200px;
    height: auto;
    margin: 0 auto;
    border-radius: 1rem;
  }
  .imageText{
    opacity: 0;
    position: absolute;
    margin: 0 auto;
    bottom: 5px;
    max-width: 200px;
    min-width: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white; /* Text color */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 5px; /* Padding around the text */
    border-radius: 3px; /* Optional: Rounded corners */
    transition: 0.2s ease-in-out;
  }
  .imageWrapper:hover .imageText{
    opacity: 1;
  }

  .slick-dots li button:before {
    color: #ffffff;
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    color: #ffffff;
  }
  
  @media (max-width: 800px){
   
    .carousel-image{
      width: 300px;
    }

  }