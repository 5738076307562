.doubleImage img{
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease-in-out;
    border-radius: 1rem;
}

.doubleImage-top{
    opacity: 1;
}
.doubleImage:hover  .doubleImage-top{
    opacity: 0;
}