.BundleDisplay-Wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    justify-items: center;
}
.BundleDisplay-Main{
    display: flex;
    flex-direction: row;
    width: max-content;
    background: linear-gradient(-10deg, #0D1321, #18233f); 
    border-radius: 0.5rem;
    border: 1px solid #18233f;
    padding: 1rem 2rem;
    gap: 40px;
    margin: 1rem 0;
}
.BundleDisplay-Left{
    display: flex;
    flex-direction: column;
    max-width: 300px;
}
.BundleDisplay-Left img{
    max-width: 300px;
    border-radius: 0.5rem ;
}
.BundleDisplay-Right{
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.BundleDisplay-Right li{
    font-size: 1.2rem;
    list-style: none;
    text-align: start;
}
.BundleDisplay-Link{
    text-align: center;
    border: 1px solid white;
    border-radius: 0.2rem;
    padding: 3px 0;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}
.BundleDisplay-Link:hover{
    background-color: white;
    color: #0D1321;

}
.BundleDisplay-NotAvailable{
    margin: 20vh 0;
}
.BundleDisplay-NotAvailable p{
    font-size: 1.2rem;
}

@media (max-width: 700px){

  .BundleDisplay-Main{
    flex-direction: column;
    gap: 0;
    align-items: center;

  }
  .BundleDisplay-Left h2{
    font-size: 1.8rem;
    margin: 2px;
  }
  .BundleDisplay-Link{
    margin-top: 1rem;
  }
}