  :root {
      --navbar-height: 60px;
      --backgroundColorHeader: #0D1321; 
      --accentColorHeader: white;
      --textcolor: white;
      /* #e3ded1; */
    }
    .mobileText {
      display: none;
    }
    .desktopText {
      display: block;
    }
    
    .Header-Nav {
      width: 100%;
      overflow: visible;
      height: var(--navbar-height);
      background-color: var(--backgroundColorHeader);
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 10;
      top:0;
      position: sticky;
      box-shadow: 0 1px 0 rgba(255,255,255,01);
    }
    .Header-Nav #logo {
      margin: 15px 0px 10px 50px;
      max-height: 40px;
    }
    .Header-Nav ul {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      list-style: none;
    }
    .Header-Nav li {
      height: 100%;
      width: 150px;
      padding: 0 10px 0 10px;
      text-align: center;
      position: relative;
    }
    
    .Header-Nav ul a {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: var(--textcolor);
      cursor: default;
    }

    .Nav-selecText{
      background-color: transparent;
      border-radius: 5px;
      padding: 10px 15px 10px 15px;
      transition: 0.1s ease-in-out;
  }

    .Nav-selecText:hover{
      background-color: var(--accentColorHeader);
      color: var(--backgroundColorHeader);
      cursor: pointer;
    }
    #myWatchesIcon{
      height: 40px;
      cursor: pointer;
    }

    .Header-Nav input[type="checkbox"] {
      display: none;
    }
    .toggle_button {
      width: 30px;
      height: 23px;
      position: absolute;
      top: 25px;
      right: 25px;
      display: none;
      flex-direction: column;
      justify-content: space-between;
    }
    .bar {
      height: 4px;
      width: 100%;
      background: var(--textcolor);
      border-radius: 100px;
    }
    #triangle {
      transform: rotate(90deg);
    }
    
    @media (max-width: 850px) {
      .toggle_button {
        display: flex;
      }
      .Header-Nav ul {
        height: min-content;
        width: 100%;
        background: var(--backgroundColorHeader);
        display: none;
        position: absolute;
        top: 45px;
        padding: 0;
        border-bottom: 1px solid white;
      }
      .Header-Nav li {
        height: min-content;
        width: 100%;
        background: var(--backgroundColorHeader);
        padding: 0;
      }
      .Header-Nav ul a {
        padding: 20px 0;
      }
    
      #toggle_button:checked ~ ul {
        display: block;
      }
    
      #toggle_button:checked + .toggle_button #bar1 {
        transform: rotate(-45deg) translate(-7px, 6px);
        transition-duration: 0.3s;
      }
    
      #toggle_button:checked + .toggle_button #bar2 {
        opacity: 0;
        transition-duration: 0.3s;
      }
    
      #toggle_button:checked + .toggle_button #bar3 {
        transform: rotate(45deg) translate(-7px, -6px);
        transition-duration: 0.3s;
      }
      #toggle_button + .toggle_button #bar1 {
        transition-duration: 0.3s;
      }
    
      #toggle_button + .toggle_button #bar2 {
        transition-duration: 0.3s;
      }
    
      #toggle_button + .toggle_button #bar3 {
        transition-duration: 0.3s;
      }
    }
    