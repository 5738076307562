.bigCard{
    width: min-content;
    border-radius: 1rem;
    padding:  0 0 1rem 0;
    margin: 0 1rem 0 1rem;
    background-color: transparent;
}
.bigCard-image img{
    border-radius: 0.8rem;
    height: 200px;
    width: auto;
}
.bigCard-bottomtext{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1rem;
}
.bigCard-Auflistung{
    margin-top: -1rem;
    text-align: start;
    width: 90%;
    color: white;
}
.bigCard-List-Link-Seperator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
}
.bigCard-Link-Wrapper a{
    color:white;
}
    .bigCard-bottomtext h3{
        font-size: 30px;
    }
    .bigCard-List-Wrapper{
        margin: 0;
        padding: 0;
    }
    .bigCard-List-Wrapper ul{
        margin-left: 10px;
        padding: 0;
    }


 @media (max-width: 1100px){
    .bigCard{
        margin: 1rem 1rem;
    }
} 
